import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import { Container, Row, Col, useScreenClass, Hidden } from "react-grid-system"
import styled from "styled-components"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import SEO from "../components/seo"
import Layout from "../components/layout"
import Main from "../components/main"
import ContactForm from "../components/forms/contactForm"

const StyledMain = styled(Main)`
  @media only screen and (max-width: ${(props) =>
      props.theme.breakpoints.tablet}) {
    padding-bottom: 0;
  }
`

const InnerCol = styled(Col)`
  max-width: 596px !important;
  @media only screen and (max-width: 1366px) {
    max-width: 100% !important;
  }
  @media only screen and (max-width: 1028px) {
  }
  @media only screen and (max-width: 768px) {
  }
`

const IframeWrapper = styled.div`
  overflow: hidden;
  position: relative;
  padding-top: 56.25%;
  iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`

const ContactPage = (props) => {
  const { seoTitle, seoDescription, seoHelmet } = props.data.contentfulPage
  const screenClass = useScreenClass()
  const iframeHtml = `<iframe style="border: 0;" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3345.2383603620106!2d-117.28390118441287!3d33.023850178665!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dc0c1cf185d64b%3A0x1564da1fb547e3d1!2sParkerWhite+Brand+Interactive!5e0!3m2!1sen!2sus!4v1454809427306" width="900" height="450" frameborder="0" allowfullscreen="allowfullscreen"></iframe>`
  const iframe = () => {
    return {
      __html: iframeHtml,
    }
  }
  return (
    <>
      <SEO
        title={seoTitle ? seoTitle : ``}
        description={
          seoDescription && seoDescription.seoDescription
            ? seoDescription.seoDescription
            : ``
        }
        pageUrl={props?.location?.href}
      />
      <Layout>
        <StyledMain>
          <Container
            style={{
              maxWidth: "100%",
              paddingLeft: 0,
              paddingRight: 0,
            }}
          >
            <Row>
              <Col lg={6}>
                <Row justify="end">
                  <InnerCol
                    style={{
                      paddingLeft: ["md", "lg", "xl"].includes(screenClass)
                        ? "3.4rem"
                        : "33px",
                      paddingRight: ["md", "lg", "xl"].includes(screenClass)
                        ? ".4rem"
                        : "33px",
                    }}
                  >
                    <h1 className={`headline`}>
                      {props.data.contentfulPage.mainHeadline}
                    </h1>
                    {documentToReactComponents(
                      JSON.parse(props.data.contentfulPage.text.raw)
                    )}
                  </InnerCol>
                </Row>
              </Col>
              <Col>
                <ContactForm />
              </Col>
            </Row>
          </Container>
        </StyledMain>
        <IframeWrapper dangerouslySetInnerHTML={iframe()} />
      </Layout>
    </>
  )
}

export const query = graphql`query ContactQuery {
  sidebarImage: file(relativePath: {eq: "social-mockup.png"}) {
    childImageSharp {
      gatsbyImageData(width: 960, quality: 95, layout: CONSTRAINED)
    }
  }
  contentfulPage(slug: {eq: "contact"}) {
    id
    slug
    name
    mainHeadline
    text {
      raw
    }
    seoTitle
    seoDescription {
      seoDescription
    }
    seoHelmet {
      seoHelmet
    }
  }
}
`

export default ContactPage
